import { IndustryTags } from 'components/IndustryTags';
import {
  RESPONSE_DATA
} from 'redux/constants';
export const name = 'response';
const initialState = {
  loading: false,
  retrievalLoading: false,
  internalTagsAPILoading: false,
  error: false,
  errorMessage: '',
  retrievalError: false,
  retrievalErrorMessage: '',
  selectedTranscript: '',
  latestSearchText: '',
  response: [],
  retrievalResult: [],
  messages: [],
  chatHistoryId: null,
  hasSentInitialMessage: false,
  messageSources: {},
  fetchController: null,
  filters: {
    monthOfInterviewRange: {
      startMonth: null,
      endMonth: null
    }
  },
  companyFilterApplied: 0,
  sourceFilterApplied: [],
  industryFilterApplied: 0,
  functionFilterApplied: 0,
  monthOfInterviewRangeValue: "",
  currentPage: 0,
  isLastPage: false,
  scrollLoading: false,
};

export const selectors = {
  getLoading: (state) => state[name].loading,
  getRetrievalLoading: (state) => state[name].retrievalLoading,
  getInternalTagsAPILoading: (state) => state[name].internalTagsAPILoading,
  getError: (state) => state[name].error,
  getRetrievalError: (state) => state[name].retrievalError,
  getErrorMessage: (state) => state[name].errorMessage,
  getRetrievalErrorMessage: (state) => state[name].retrievalErrorMessage,
  getResponse: (state) => state[name].response,
  getRetrievalResult: (state) => state[name].retrievalResult,
  getSelectedTranscript: (state) => state[name].selectedTranscript,
  getLatestSearchText: (state) => state[name].latestSearchText,
  getFilters: (state) => state[name].filters,
  getMessages: (state) => state[name].messages,
  getChatHistoryId: (state) => state[name].chatHistoryId,
  getCompanyFilterApplied: (state) => state[name].companyFilterApplied,
  getSourceFilterApplied: (state) => state[name].sourceFilterApplied,
  getIndustryFilterApplied: (state) => state[name].industryFilterApplied,
  getFunctionFilterApplied: (state) => state[name].functionFilterApplied,
  getMonthOfInterviewRangeValue: (state) => state[name].monthOfInterviewRangeValue,
  getFetchController: (state) => state[name].fetchController,
  getCurrentPage: (state) => state[name].currentPage,
  getIsLastPage: (state) => state[name].isLastPage,
  getScrollLoading: (state) => state[name].scrollLoading,
};

export function reducer(state = initialState, action) {
  const { payload = {} } = action;
  const { response = [], error } = payload;
  switch (action.type) {
    case RESPONSE_DATA.RESPONSE_API_RESULTS_PENDING:
      return {
        ...state,
        retrievalLoading: true,
        retrievalError: false
      };
    case RESPONSE_DATA.RESPONSE_API_SCROLL_LOADING:
      return {
        ...state,
        scrollLoading: true,
        retrievalError: false
      };
    case RESPONSE_DATA.RESPONSE_API_RESULTS_FAILURE:
      return {
        ...state,
        retrievalError: true,
        errorMessage: error,
        retrievalLoading: false,
      };
    case RESPONSE_DATA.RESPONSE_API_RESULTS_SUCCESS:
      return {
        ...state,
        response: [
          ...state.response, response
        ],
        retrievalLoading: false,
        retrievalError: false
      };
    case RESPONSE_DATA.RETRIEVAL_API_RESULTS_SUCCESS:
      return {
        ...state,
        retrievalResult: response,
        retrievalLoading: false,
        scrollLoading: false,
        retrievalError: false
      };
    case RESPONSE_DATA.UPDATE_TRANSCRIPT_IN_QUERY_RESULTS:
      const existingResult = state.retrievalResult;
      existingResult.forEach((item, index) => {
        if (item.meeting_id === response.meeting_id) {
          existingResult[index] = response;
        }
      })
      return {
        ...state,
        retrievalResult: [...existingResult]
      };
    case RESPONSE_DATA.CLEAR_TRANSCRIPT_SELECTED:
      return {
        ...state,
        selectedTranscript: response
      };
    case RESPONSE_DATA.UPDATE_TRANSCRIPT_SELECTED:
      const existingSelectedTranscript = state.selectedTranscript;
      return {
        ...state,
        selectedTranscript: {
          ...existingSelectedTranscript,
          ...response
        }
      };
    case RESPONSE_DATA.SELECT_TRANSCRIPT:
      const existingSelectedTranscript1 = state.selectedTranscript;
      return {
        ...state,
        selectedTranscript: {
          ...existingSelectedTranscript1,
          ...{
            generated_summary: "",
            generated_title: ""
          },
          ...response
        }
      };
    case RESPONSE_DATA.SET_LATEST_SEARCH_TEXT:
      return {
        ...state,
        latestSearchText: response
      };
    case RESPONSE_DATA.COMPANY_FILTER_APPLIED:
      return {
        ...state,
        companyFilterApplied: response

      };
    case RESPONSE_DATA.INDUSTRY_FILTER_APPLIED:
      return {
        ...state,
        industryFilterApplied: response

      };
    case RESPONSE_DATA.FUNCTION_FILTER_APPLIED:
      return {
        ...state,
        functionFilterApplied: response
      };
    case RESPONSE_DATA.SOURCE_FILTER_APPLIED:
      return {
        ...state,
        sourceFilterApplied: response
      };
    case RESPONSE_DATA.MONTH_OF_INTERVIEW_VALUE:
      return {
        ...state,
        monthOfInterviewRangeValue: response
      };
    case RESPONSE_DATA.SET_FILTERS:
      return {
        ...state,
        filters: { ...state.filters, ...response }
      };
    case RESPONSE_DATA.GET_FILE_FAILURE:
      return {
        ...state,
        retrievalLoading: false,
      };
    case RESPONSE_DATA.CHAT_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: '',
      };
    case RESPONSE_DATA.CHAT_SUCCESS:
      // replace the last message
      // with the processed one
      const existingMessages = state.messages;
      existingMessages.pop();
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: '',
        messages: [
          ...existingMessages,
          action.payload
        ]
      };
    case RESPONSE_DATA.CHAT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload?.message,
      };
    case RESPONSE_DATA.SET_FETCH_CONTROLLER:
      return {
        ...state,
        fetchController: action.payload
      };
    case RESPONSE_DATA.REMOVE_FETCH_CONTROLLER:
      return {
        ...state,
        loading: false,
        fetchController: null
      };
    case RESPONSE_DATA.RESET_CHAT_STATE:
      if (state.fetchController !== null) {
        state.fetchController.abort('User cancelled/reset chat');
      }
      return initialState;
    case RESPONSE_DATA.CHAT_ADD_MESSAGE:
      const msgs = state.messages;
      return {
        ...state,
        messages: [...msgs, action.payload]
      };
    case RESPONSE_DATA.CHAT_CLEAR_ALL_MESSAGES:
      return {
        ...state,
        messages: []
      };
    case RESPONSE_DATA.CHAT_DELETE_MESSAGE:
      const _messages = state.messages;
      _messages.splice(action.payload.index, 1);
      return {
        ...state,
        messages: _messages
      };
    case RESPONSE_DATA.SET_HAS_SENT_INITIAL_MESSAGE_SUCCESS:
      return {
        ...state,
        hasSentInitialMessage: action.payload,
      };
    case RESPONSE_DATA.CHAT_STREAM_NEW_MESSAGE:
      return {
        ...state,
        messages: [
          ...state.messages,
          action.payload
        ]
      };
    case RESPONSE_DATA.CHAT_STREAM_CHUNK:
      const messages = state.messages;
      messages.find(m => m.index === action.payload.lastMessageIndex).content += action.payload.content;
      return {
        ...state,
        messages: [...messages]
      };
    case RESPONSE_DATA.CHAT_SOURCES_PENDING:
      return {
        ...state,
        messageSources: {
          ...state.messageSources,
          [action.payload.idx]: {
            sources: [],
            loading: true,
            error: false,
            errorMessage: ''
          }
        },
      };
    case RESPONSE_DATA.CHAT_SOURCES_SUCCESS:
      return {
        ...state,
        messageSources: {
          ...state.messageSources,
          [action.payload.idx]: {
            sources: action.payload.sources,
            loading: action.payload.loading,
            error: false,
            errorMessage: ''
          }
        },
      };
    case RESPONSE_DATA.CHAT_SOURCES_FAILURE:
      return {
        ...state,
        messageSources: {
          ...state.messageSources,
          [action.payload.idx]: {
            sources: state.messageSources[action.payload.idx]?.sources,
            loading: false,
            error: true,
            errorMessage: action.payload.error
          }
        },
      };
    case RESPONSE_DATA.CHAT_SET_HISTORY_ID:
      return {
        ...state,
        chatHistoryId: response
      };
    case RESPONSE_DATA.INTERNAL_TAGS_API_RESULTS_PENDING:
      return {
        ...state,
        internalTagsAPILoading: true,
      };
    case RESPONSE_DATA.INTERNAL_TAGS_API_RESULTS_SUCCESS:
    case RESPONSE_DATA.INTERNAL_TAGS_API_RESULTS_FAILURE:
      return {
        ...state,
        internalTagsAPILoading: false,
      };
    case RESPONSE_DATA.SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: response
      };
    case RESPONSE_DATA.SET_IS_LAST_PAGE:
      return {
        ...state,
        isLastPage: response
      };
    default:
      return state;
  }
}
